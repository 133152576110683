import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import apiClient from "../utils/requestHandler";
import { useNavigate, useParams } from "react-router-dom";

const SeeAndTreatForm = () => {
  const { eventID } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    event_id: eventID || "",
    patient_id: "",
    incident_date: new Date().toISOString().slice(0, 16),
    incident_location: "",
    issue_summary: "",
    treatment_provided: "",
    advice_given: "",
    clinician_name: "",
    clinician_role: "",
  });

  const [patients, setPatients] = useState([]);
  const [events, setEvents] = useState([]);
  const [newPatientName, setNewPatientName] = useState("");
  const [showPatientModal, setShowPatientModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creatingPatient, setCreatingPatient] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchPatients();
    fetchEvents();
    fetchUser();
  }, []);

  const fetchPatients = async () => {
    try {
      const res = await apiClient.get("/api/patients");
      setPatients(res.data || []);
    } catch (err) {
      console.error("Failed to fetch patients", err);
    }
  };

  const fetchEvents = async () => {
    try {
      const res = await apiClient.get("/api/events");
      setEvents(res.data || []);
    } catch (err) {
      console.error("Failed to fetch events", err);
    }
  };

  const fetchUser = async () => {
    try {
      const res = await apiClient.get("/api/auth/user", {
        withCredentials: true,
      });
      const user = res.data;
      setFormData((prev) => ({
        ...prev,
        clinician_name: `${user.firstName} ${user.lastName}`,
        clinician_role: user.role || "staff",
      }));
    } catch (err) {
      console.error("Failed to fetch user", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "event_id") {
      const selectedEvent = events.find((ev) => ev.eventID === parseInt(value));
      if (selectedEvent?.location) {
        setFormData((prev) => ({
          ...prev,
          incident_location: selectedEvent.location,
        }));
      }
    }
  };

  const handleCreatePatient = async () => {
    if (!newPatientName) return;
    setCreatingPatient(true);
    try {
      const res = await apiClient.post("/api/patients", {
        name: newPatientName,
      });
      setPatients((prev) => [...prev, res.data]);
      setFormData((prev) => ({
        ...prev,
        patient_id: res.data.patient_id,
      }));
      setShowPatientModal(false);
      setNewPatientName("");
    } catch (err) {
      console.error("Failed to create patient", err);
    } finally {
      setCreatingPatient(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await apiClient.post("/api/see-and-treat", formData);
      navigate(`/event/${formData.event_id}/see-and-treat`);
    } catch (err) {
      setError("Failed to submit See and Treat log");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        See and Treat Log
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="event_id"
            label="Select Event"
            value={formData.event_id}
            onChange={handleChange}
          >
            {events.map((ev) => (
              <MenuItem key={ev.eventID} value={ev.eventID}>
                {ev.name} ({ev.date})
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            name="patient_id"
            label="Select Patient"
            value={formData.patient_id}
            onChange={handleChange}
          >
            {patients.map((pt) => (
              <MenuItem key={pt.patient_id} value={pt.patient_id}>
                {pt.name}
              </MenuItem>
            ))}
          </TextField>
          <Button onClick={() => setShowPatientModal(true)} sx={{ mt: 1 }}>
            + Add New Patient
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            type="datetime-local"
            label="Incident Date"
            name="incident_date"
            value={formData.incident_date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="incident_location"
            label="Incident Location"
            value={formData.incident_location}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            name="issue_summary"
            label="Issue Summary"
            value={formData.issue_summary}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            name="treatment_provided"
            label="Treatment Provided"
            value={formData.treatment_provided}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            name="advice_given"
            label="Advice Given"
            value={formData.advice_given}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            name="clinician_name"
            label="Clinician Name"
            value={formData.clinician_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            name="clinician_role"
            label="Clinician Role"
            value={formData.clinician_role}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              "Submit See and Treat Log"
            )}
          </Button>
        </Grid>
      </Grid>

      {/* Patient Modal */}
      <Dialog
        open={showPatientModal}
        onClose={() => setShowPatientModal(false)}
      >
        <DialogTitle>Add New Patient</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Patient Name"
            value={newPatientName}
            onChange={(e) => setNewPatientName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPatientModal(false)}>Cancel</Button>
          <Button onClick={handleCreatePatient} disabled={creatingPatient}>
            {creatingPatient ? <CircularProgress size={20} /> : "Add Patient"}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SeeAndTreatForm;
