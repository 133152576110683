import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import "./LoadingAnimation.css"; // Includes pulse, blink, heartbeat, etc.

const loadingComments = [
  "Dispatching medics to fix the internet...",
  "Hold tight, we're on a life-saving mission!",
  "Counting down heartbeats...",
  "Sirens on! Loading faster than you can say '999'!",
  "Administering data IV drip...",
  "Stand by – blue lights activated!",
  "Loading... just like waiting for an ambulance in rush hour!",
  "Medic on the way – loading like it’s an emergency!",
];

const getRandomComment = () => {
  return loadingComments[Math.floor(Math.random() * loadingComments.length)];
};

const LoadingScreen = () => {
  const [comment, setComment] = useState(getRandomComment());

  useEffect(() => {
    const interval = setInterval(() => {
      setComment(getRandomComment());
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "radial-gradient(#0a0a0a, #1a1a1a)",
        color: "#fff",
        overflow: "hidden",
      }}
    >
      {/* Flashing Red/Blue Emergency Lights */}
      <Box className="emergency-light red" />
      <Box className="emergency-light blue" />

      {/* Pulsing Trauma Circle */}
      <Box
        sx={{ position: "relative", zIndex: 10, textAlign: "center" }}
        className="pulse-circle"
      />

      {/* ECG Line */}
      <Box
        sx={{ position: "relative", zIndex: 10, textAlign: "center" }}
        className="ecg-line"
      />

      {/* Center Text Content */}
      <Box sx={{ position: "relative", zIndex: 10, textAlign: "center" }}>
        <Typography
          variant="overline"
          sx={{
            fontWeight: "bold",
            color: "#ff0033",
            letterSpacing: "1px",
            mb: 1,
            textShadow: "0 0 6px rgba(255, 0, 0, 0.6)",
          }}
        >
          Episode 47: Server in Shock
        </Typography>

        <CircularProgress
          sx={{
            color: "#ff0033",
            animation: "heartbeat 2s infinite",
            marginBottom: "20px",
          }}
          size={70}
          thickness={6}
        />

        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textShadow: "0px 0px 10px rgba(255, 0, 0, 0.7)",
          }}
        >
          {comment}
        </Typography>

        <Typography
          variant="subtitle2"
          sx={{
            marginTop: "10px",
            color: "#ccc",
            textShadow: "0px 0px 5px rgba(255, 255, 255, 0.3)",
          }}
        >
          Please wait while we stabilise the platform...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
