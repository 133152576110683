import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiClient from "../utils/requestHandler";
import { useLoading } from "../contexts/LoadingContext";
import { Box, CircularProgress } from "@mui/material";

function AuthLoader({ children }) {
  const [user, setUser] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const location = useLocation();

  const setupModeEnabled = process.env.REACT_APP_SETUP_MODE === "true";
  const currentPath = location.pathname;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const res = await apiClient.get("/api/auth/user", {
          withCredentials: true,
        });
        setUser(res.data);
      } catch {
        setUser(null);
      } finally {
        setLoading(false);
        setUserLoaded(true);
      }
    };

    if (!userLoaded) fetchUser();
  }, [userLoaded, setLoading]);

  useEffect(() => {
    if (userLoaded) {
      const publicRoutes = ["/login", "/patient-register", "/createadmin"];

      if (
        !user &&
        !publicRoutes.includes(currentPath) &&
        !(currentPath === "/createadmin" && setupModeEnabled)
      ) {
        navigate("/login", { replace: true });
      } else if (user && publicRoutes.includes(currentPath)) {
        const target =
          user.role === "admin" || user.role === "staff"
            ? "/dashboard"
            : user.role === "client"
            ? "/client"
            : user.role === "patient"
            ? "/patient-access"
            : "/login";
        navigate(target, { replace: true });
      }
    }
  }, [user, userLoaded, currentPath, navigate, setupModeEnabled]);

  if (!userLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user && currentPath === "/createadmin" && setupModeEnabled) {
    return children;
  }

  return children;
}

export default AuthLoader;
