import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  Dashboard,
  Event,
  People,
  Inventory,
  Assessment,
  Assignment,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import apiClient from "../utils/requestHandler";
import { useAuth } from "../contexts/AuthContext";

const getNavBarStyles = (role) => {
  const styles = {
    admin: { bg: "#1976d2", color: "#fff" },
    staff: { bg: "#2196f3", color: "#fff" },
    client: { bg: "#9c27b0", color: "#fff" },
    patient: { bg: "#4caf50", color: "#fff" },
    default: { bg: "#2c2c2c", color: "#fff" },
  };
  return styles[role] || styles.default;
};

const getNavItemIcon = (label) => {
  switch (label) {
    case "Dashboard":
      return <Dashboard />;
    case "Shifts":
      return <Event />;
    case "Create PRF":
      return <Assignment />;
      case "Create S&T":
      return <Assignment />;
    case "Patients":
      return <People />;
    case "Inventory":
      return <Inventory />;
    case "Audit Logs":
      return <Assessment />;
    case "My Events":
      return <Event />;
    case "My Records":
      return <People />;
    default:
      return null;
  }
};

const NavBar = () => {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navBarStyles = getNavBarStyles(user?.role);

  const handleLogout = async () => {
    try {
      setLoading(true);
      await apiClient.post("/api/auth/logout");
      setUser(null);
      navigate("/login", { replace: true });
    } catch (err) {
      console.error("Error logging out:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  const renderButtonsByRole = () => {
    if (!user) return [];

    switch (user.role) {
      case "admin":
      case "staff":
        return [
          { label: "Dashboard", path: "/dashboard" },
          { label: "Shifts", path: "/shifts" },
          { label: "Create PRF", path: "/createprf" },
          { label: "Create S&T", path:"/see-and-treat"},
          { label: "Patients", path: "/patients" },
          { label: "Inventory", path: "/inventory/dashboard" },
          { label: "Audit Logs", path: "/reports/auditlog" },
        ];
      case "client":
        return [{ label: "My Events", path: "/client" }];
      case "patient":
        return [{ label: "My Records", path: "/patient-access" }];
      default:
        return [];
    }
  };

  const navItems = renderButtonsByRole();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: 1300,
          background: (theme) =>
            `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
          color: (theme) => theme.palette.primary.contrastText,
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            DC PatientLink
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                startIcon={getNavItemIcon(item.label)}
                sx={{
                  mx: 1,
                  color: "inherit",
                  "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
                }}
                onClick={() => navigate(item.path)}
              >
                {item.label}
              </Button>
            ))}
            <Button
              onClick={handleLogout}
              startIcon={<Logout />}
              sx={{
                color: "inherit",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
              }}
            >
              Logout
            </Button>
          </Box>

          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ zIndex: 1200 }}
      >
        <Box sx={{ width: 250, p: 2 }}>
          <Typography
            variant="h6"
            sx={{ mb: 2, cursor: "pointer" }}
            onClick={() => {
              navigate("/dashboard");
              setDrawerOpen(false);
            }}
          >
            DC PatientLink
          </Typography>
          <List>
            {navItems.map((item) => (
              <ListItem
                button
                key={item.label}
                onClick={() => {
                  navigate(item.path);
                  setDrawerOpen(false);
                }}
              >
                <ListItemIcon>{getNavItemIcon(item.label)}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
            <ListItem
              button
              onClick={() => {
                handleLogout();
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default NavBar;
