import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import {
  ThemeProvider,
  CssBaseline,
  CircularProgress,
  Box,
} from "@mui/material";
import { generateTheme } from "./theme"; // Import the dynamic theme generator
import ErrorBoundary from "./components/ErrorBoundary";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { PRFProvider } from "./utils/PRFContext";
import { AuthProvider } from "./contexts/AuthContext";
import { LoadingProvider, useLoading } from "./contexts/LoadingContext";
import AuthLoader from "./components/AuthLoader";
import PrivateRoute from "./components/PrivateRoute";
import LoadingScreen from "./components/LoadingScreen";
import PatientRegister from "./pages/PatientRegister";
import KioskMode from "./components/PRF/KioskMode";
import { useAuth } from "./contexts/AuthContext";
import NotFound from "./pages/NotFound";
import ShiftCalendar from "./pages/Calendar";
import LocationsPage from "./pages/Stock/LocationPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SeeAndTreatForm from "./components/SeeAndTreatForm";

// Lazy-loaded pages and components
const LoginPage = lazy(() => import("./pages/LoginPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const CreateAdminUserPage = lazy(() => import("./pages/CreateAdminUserPage"));
const ClientEvents = lazy(() => import("./pages/Client/ClientEvents"));
const EventDetails = lazy(() => import("./pages/Client/EventDetails"));
const EventDashboard = lazy(() => import("./pages/Client/EventDashboard"));
const CreatePRF = lazy(() => import("./components/CreatePRF"));
const AuditLogsPage = lazy(() => import("./pages/Reports/AuditLogReport"));
const CheerTeamsPage = lazy(() => import("./pages/Reports/CheerTeamReport"));
const PatientDashboard = lazy(() => import("./pages/Patient/PatientDashboard"));
const PatientRecord = lazy(() => import("./pages/Patient/PatientDetails"));
const IMSDashboard = lazy(() => import("./pages/Stock/IMSDashboard"));
const InventoryOverview = lazy(() => import("./pages/Stock/InventoryOverview"));
const ItemDetails = lazy(() => import("./pages/Stock/ItemDetails"));
const BOMsPage = lazy(() => import("./pages/Stock/BOMs"));

const App = () => {
  return (
    <LoadingProvider>
      <AuthProvider>
        <InnerApp />
      </AuthProvider>
    </LoadingProvider>
  );
};

const InnerApp = () => {
  const { user, userLoaded } = useAuth(); // Get user from AuthContext
  const [theme, setTheme] = useState(generateTheme(user?.role || "default"));

  useEffect(() => {
    if (userLoaded) {
      setTheme(generateTheme(user?.role || "default"));
    }
  }, [user, userLoaded]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PRFProvider>
        <ErrorBoundary>
          <Router>
            <MainLayout />
          </Router>
        </ErrorBoundary>
      </PRFProvider>
    </ThemeProvider>
  );
};

const MainLayout = () => {
  const location = useLocation();
  const { loading, setLoading } = useLoading();

  const hideNavBar = ["/login", "/patient-register"].some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {!hideNavBar && <NavBar />}
      <Box
        component="main"
        sx={{
          flex: 1,
          mt: !hideNavBar ? { xs: "64px", sm: "64px" } : 0,
          px: 2,
        }}
      >
        <AuthLoader>
          {console.log("AuthLoader - Rendering Main Content")}
          <Suspense
            fallback={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100vh",
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            {console.log("Suspense - Routes Rendering")}
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/patient-register" element={<PatientRegister />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              {/* Admin Routes */}
              <Route
                path="/createadmin"
                element={
                  <PrivateRoute allowedRoles={["admin"]}>
                    <CreateAdminUserPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <DashboardPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shifts"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <ShiftCalendar />
                  </PrivateRoute>
                }
              />

              {/* Inventory Routes */}
              <Route
                path="/inventory/dashboard"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <IMSDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inventory/items"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <InventoryOverview />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inventory/items/:itemId"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <ItemDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inventory/boms"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <BOMsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/inventory/locations"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <LocationsPage />
                  </PrivateRoute>
                }
              />

              {/* Reports Routes */}
              <Route
                path="/reports/auditlog"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <AuditLogsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports/cheerreport/:eventID"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <CheerTeamsPage />
                  </PrivateRoute>
                }
              />

              {/* PRF Routes */}
              <Route
                path="/createprf"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <CreatePRF />
                  </PrivateRoute>
                }
              />
              <Route
                path="/prf/:custom_prf_id"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <CreatePRF />
                  </PrivateRoute>
                }
              />
              <Route path="/prf/:custom_prf_id/kiosk" element={<KioskMode />} />

              <Route
                path="/see-and-treat"
                element={<SeeAndTreatForm />}
              />

              {/* Client Routes */}
              <Route
                path="/client"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff", "client"]}>
                    <ClientEvents />
                  </PrivateRoute>
                }
              />
              <Route
                path="/client/event/:eventID"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff", "client"]}>
                    <EventDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/client/event/:eventID/dashboard"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff", "client"]}>
                    <EventDashboard />
                  </PrivateRoute>
                }
              />

              {/* Patient Routes */}
              <Route
                path="/patients"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff"]}>
                    <PatientDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/patients/:patient_id"
                element={
                  <PrivateRoute allowedRoles={["admin", "staff", "patient"]}>
                    <PatientRecord />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </AuthLoader>
      </Box>
      {!hideNavBar && <Footer />}
    </Box>
  );
};

export default App;
