import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const messages = [
  "Maybe the page is on a coffee break ☕️.",
  "Well... this isn’t the page you were looking for. *waves hand like a Jedi*",
  "404: The page ye seek hath vanished into the digital abyss.",
  "Whoops! Someone must've unplugged the internet again.",
  "It was here a second ago. Promise.",
  "404. Not all those who wander are lost. Except this page. It’s definitely lost.",
  "Error: Reality not found. Reboot your expectations.",
  "Congratulations! You’ve found the secret level of 'Oops!'.",
];

const NotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
        padding: 3,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: "bold",
          color: theme.palette.primary.main,
          mb: 2,
          fontSize: { xs: "3rem", sm: "5rem" },
        }}
      >
        404
      </Typography>

      <Typography
        variant="h5"
        sx={{
          mb: 2,
          color: theme.palette.text.secondary,
          fontStyle: "italic",
        }}
      >
        {randomMessage}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          mb: 4,
          maxWidth: "600px",
          color: theme.palette.text.secondary,
        }}
      >
        This page either never existed, spontaneously combusted, or took an
        early retirement. But hey — you're still here, and that's what matters.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate("/dashboard")}
        sx={{
          textTransform: "none",
          fontWeight: "bold",
          px: 4,
          py: 1.5,
        }}
      >
        🚀 Back to Safety
      </Button>
    </Box>
  );
};

export default NotFound;
