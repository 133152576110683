import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import LoadingScreen from "../components/LoadingScreen";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false); // the actual loading trigger
  const [shouldShowLoading, setShouldShowLoading] = useState(false); // controls if UI displays
  const startTimeRef = useRef(null); // track when loading started

  useEffect(() => {
    let timeout;

    if (loading) {
      startTimeRef.current = Date.now();
      setShouldShowLoading(true);
    } else if (shouldShowLoading) {
      const elapsed = Date.now() - startTimeRef.current;
      const remaining = 3000 - elapsed; // minimum 1s
      timeout = setTimeout(
        () => {
          setShouldShowLoading(false);
        },
        remaining > 0 ? remaining : 0
      );
    }

    return () => clearTimeout(timeout);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      <>
        {shouldShowLoading && <LoadingScreen />}
        {children}
      </>
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
